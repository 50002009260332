<template>
    <svg
        width="89"
        height="89"
        viewBox="0 0 89 89"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            opacity="0.5"
            cx="44.5"
            cy="44.5"
            r="43.5"
            stroke="#FFEFE1"
            stroke-width="2"
        />
        <path
            d="M58.5668 54.6479L48.3621 44.4433L58.9069 33.8985C59.373 33.4324 59.6408 32.8062 59.6515 32.1577C59.6621 31.5091 59.4147 30.8914 58.9636 30.4403C58.5125 29.9892 57.8948 29.7418 57.2462 29.7524C56.5977 29.7631 55.9715 30.0309 55.5054 30.497L44.9606 41.0418L34.7559 30.8371C34.3049 30.3861 33.6871 30.1386 33.0386 30.1493C32.39 30.1599 31.7638 30.4277 31.2977 30.8938C30.8316 31.3599 30.5638 31.9861 30.5531 32.6347C30.5425 33.2832 30.7899 33.901 31.241 34.3521L41.4456 44.5567L30.9009 55.1015C30.4348 55.5676 30.1669 56.1938 30.1563 56.8423C30.1457 57.4909 30.3931 58.1086 30.8442 58.5597C31.2952 59.0108 31.913 59.2582 32.5615 59.2476C33.2101 59.237 33.8363 58.9691 34.3024 58.503L44.8472 47.9582L55.0518 58.1629C55.5029 58.614 56.1207 58.8614 56.7692 58.8508C57.4177 58.8401 58.044 58.5723 58.5101 58.1062C58.9762 57.6401 59.244 57.0139 59.2546 56.3653C59.2653 55.7168 59.0178 55.099 58.5668 54.6479Z"
            fill="#FFFFFF"
        ></path>
    </svg>
</template>
